import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";

import Button from "components/Button";
import VenueCard from "components/VenueCard";
// import { venues } from "config";
import { partyVenue } from "config";
// import { partyVenue3 } from "config";


const Party = () => {
//  const venueItem=venues[0];
  const venueItem2=partyVenue;
  // const venueItem3=partyVenue3;
  return (
    <article className="w-full">
      <PageTitle>Preparty a Afterparty</PageTitle>

      <Helmet>
        <title>Preparty a Afterparty | {basics.appTitle}</title>
        <meta property="og:title" content="Preparty a Afterparty" />
      </Helmet>

      <div className="content-block">
      
              <h3>Preparty</h3>

        <p>
            <a href="https://www.facebook.com/events/1775742106553912/">Oficiální páteční preparty</a> před zasedáním CF se uskuteční v centru Zlína v klubu SKLEP 33. O hudební náplň se tradičně postarají Pirate DJs, kteří si chystají několik stylů elektronické hudby.
        </p>

        <h4>Lineup</h4>
       
        
        <p>
        <ul>
            <li>20:00 - 21:30 MAGNETIC VOID (melodic)</li>
            <li>21:30 - 23:00 MARRA (psytrance)</li>
            <li>23:00 - 00:30 ZDENEK KEY (psytrance / dnb)</li>
            <li>00:30 - 02:00 PUNKIE (dnb)</li>
            <li>02:00 - ??:?? LOSNADLOS (tekno / hc)</li>
        </ul>
        </p>
        
      <div className="grid md:grid-cols-2 gap-4 md:gap-4 content-block">
        <VenueCard
          street={venueItem2.street}
          city={venueItem2.city}
          gps={venueItem2.gps}
          href={venueItem2.href}
          title_short={venueItem2.title_short}
        />
      </div>

        <h3>Afterparty</h3>

        <p>

Oficiální sobotní afterparty proběhne v podniku Pizzeria Bowling Bar, který přímo sousedí s hlavním sálem našeho zasedání v druhém patře Interhotelu. Počítá se s hudebníky z řad členstva a oblíbeným “jamováním”. Otevřeno bude do 02:00, “békat” můžeme do půlnoci. HUDEBNÍ NÁSTROJE S SEBOU! 🙂</p>
<p><b>V 19:30 bude hudební překvapení!</b></p>

      <div className="grid md:grid-cols-2 gap-4 md:gap-4 content-block">
        {/*
        <VenueCard
          street={venueItem3.street}
          city={venueItem3.city}
          gps={venueItem3.gps}
          href={venueItem3.href}
          title_short={venueItem3.title_short}
        />
        */}
        <VenueCard
    street="náměstí Práce 2512"
    city="760 01 Zlín"
    gps="49.221116N,17.661250E"
    href="https://interhotelzlin.cz/gastronomie/pizzeria-bowling-bar/"
    title_short="Interhotel Zlín - Pizzeria Bowling Bar"
  />

      </div>
      </div>
    </article>
  );
};

export default Party;
